const productsData = [
  {
    id: 1,
    tag: "hero-product",
    tagline: "Stylish and Comfortable for Every Occasion.",
    heroImage: "/images/clothes/dress1-hero.png",
    images: [
      "/images/clothes/dress1-1.png",
      "/images/clothes/dress1-2.png",
      "/images/clothes/dress1-3.png",
      "/images/clothes/dress1-4.png",
    ],
    brand: "Zara",
    title: "Summer Floral Dress",
    info: "Women's Casual Summer Dress",
    category: "Women",
    type: "Dress",
    size: "M, L, XL",
    finalPrice: 2499,
    originalPrice: 3499,
    quantity: 1,
    ratings: 345,
    rateCount: 4,
    path: "/product-details/",
  },
  {
    id: 2,
    tag: "featured-product",
    images: [
      "/images/clothes/shirt1-1.png",
      "/images/clothes/shirt1-2.png",
      "/images/clothes/shirt1-3.png",
      "/images/clothes/shirt1-4.png",
    ],
    brand: "H&M",
    title: "Women's Casual Shirt",
    info: "Cotton Slim Fit Shirt",
    category: "Men",
    type: "Shirt",
    size: "S, M, L, XL",
    finalPrice: 999,
    originalPrice: 1499,
    quantity: 1,
    ratings: 289,
    rateCount: 4,
    path: "/product-details/",
  },
  {
    id: 3,
    tag: "hero-product",
    tagline: "Soft, Cozy, and Perfect for Play.",
    heroImage: "/images/clothes/kids1-hero.png",
    images: [
      "/images/clothes/kids1-1.png",
      "/images/clothes/kids1-2.png",
      "/images/clothes/kids1-3.png",
      "/images/clothes/kids1-4.png",
    ],
    brand: "GAP Kids",
    title: "Kids' Fun T-Shirt",
    info: "Cotton T-Shirt for Kids",
    category: "Kids",
    type: "T-Shirt",
    size: "2-3Y, 3-4Y, 4-5Y",
    finalPrice: 599,
    originalPrice: 899,
    quantity: 1,
    ratings: 178,
    rateCount: 5,
    path: "/product-details/",
  },
  {
    id: 4,
    tag: "featured-product",
    images: [
      "/images/clothes/jeans1-1.png",
      "/images/clothes/jeans1-2.png",
      "/images/clothes/jeans1-3.png",
      "/images/clothes/jeans1-4.png",
    ],
    brand: "Levi's",
    title: "Women's Skinny Jeans",
    info: "Stretch Denim Jeans",
    category: "Women",
    type: "Jeans",
    size: "M, L, XL",
    finalPrice: 1599,
    originalPrice: 2499,
    quantity: 1,
    ratings: 410,
    rateCount: 4,
    path: "/product-details/",
  },
  {
    id: 5,
    tag: "hero-product",
    heroImage: "/images/clothes/jacket1-hero.png",
    images: [
      "/images/clothes/jacket1-1.png",
      "/images/clothes/jacket1-2.png",
      "/images/clothes/jacket1-3.png",
      "/images/clothes/jacket1-4.png",
    ],
    brand: "Nike",
    title: "Men's Sports Jacket",
    info: "Lightweight Running Jacket",
    category: "Men",
    type: "Jacket",
    size: "M, L, XL, XXL",
    finalPrice: 3999,
    originalPrice: 5999,
    quantity: 1,
    ratings: 220,
    rateCount: 4,
    path: "/product-details/",
  },
  {
    id: 6,
    images: [
      "/images/clothes/skirt1-1.png",
      "/images/clothes/skirt1-2.png",
      "/images/clothes/skirt1-3.png",
      "/images/clothes/skirt1-4.png",
    ],
    brand: "Forever 21",
    title: "Pleated Mini Skirt",
    info: "Chic and Comfortable Mini Skirt",
    category: "Women",
    type: "Skirt",
    size: "S, M, L",
    finalPrice: 799,
    originalPrice: 1199,
    quantity: 1,
    ratings: 314,
    rateCount: 4,
    path: "/product-details/",
  },
  {
    id: 7,
    tag: "hero-product",
    tagline: "Cool and Comfortable for Everyday Wear.",
    heroImage: "/images/clothes/boys1-hero.png",
    images: [
      "/images/clothes/boys1-1.png",
      "/images/clothes/boys1-2.png",
      "/images/clothes/boys1-3.png",
      "/images/clothes/boys1-4.png",
    ],
    brand: "Carter's",
    title: "Boys' Denim Jacket",
    info: "Classic Denim for Young Adventurers",
    category: "Kids",
    type: "Jacket",
    size: "5-6Y, 6-7Y, 7-8Y",
    finalPrice: 1499,
    originalPrice: 1999,
    quantity: 1,
    ratings: 198,
    rateCount: 5,
    path: "/product-details/",
  },
  {
    id: 8,
    tag: "featured-product",
    images: [
      "/images/clothes/pants1-1.png",
      "/images/clothes/pants1-2.png",
      "/images/clothes/pants1-3.png",
      "/images/clothes/pants1-4.png",
    ],
    brand: "Puma",
    title: "Men's Jogger Pants",
    info: "Comfortable Workout Pants",
    category: "Men",
    type: "Pants",
    size: "M, L, XL",
    finalPrice: 1299,
    originalPrice: 1799,
    quantity: 1,
    ratings: 276,
    rateCount: 4,
    path: "/product-details/",
  },
  {
    id: 9,
    tag: "featured-product",
    images: [
      "/images/clothes/dress2-1.png",
      "/images/clothes/dress2-2.png",
      "/images/clothes/dress2-3.png",
      "/images/clothes/dress2-4.png",
    ],
    brand: "Mango",
    title: "Elegant Evening Dress",
    info: "Formal Gown for Special Occasions",
    category: "Women",
    type: "Dress",
    size: "M, L, XL",
    finalPrice: 4999,
    originalPrice: 7499,
    quantity: 1,
    ratings: 165,
    rateCount: 5,
    path: "/product-details/",
  },
  {
    id: 10,
    images: [
      "/images/clothes/kids2-1.png",
      "/images/clothes/kids2-2.png",
      "/images/clothes/kids2-3.png",
      "/images/clothes/kids2-4.png",
    ],
    brand: "Adidas Kids",
    title: "Kids' Tracksuit",
    info: "Breathable Tracksuit for Kids",
    category: "Kids",
    type: "Tracksuit",
    size: "3-4Y, 4-5Y, 5-6Y",
    finalPrice: 1599,
    originalPrice: 2499,
    quantity: 1,
    ratings: 133,
    rateCount: 4,
    path: "/product-details/",
  }
  // Add more products as needed
];

export default productsData;
