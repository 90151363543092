import React from "react";
const Whastapp = () => {
    
  return (
    <>
      <div
        title="Whatsapp"
        // onClick={handlewhastapp}
      >
        <a
          href="https://wa.me/+923125496244"
          className="whatsapp_float "
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp whatsapp-icon "></i>
        </a>
      </div>
    </>
  );
};

export default Whastapp;
